* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
}

html {
  font-size: 62.5%;
  --main-color: rgb(100, 151, 163);
  --secondary-color: rgb(131, 176, 187);
  --error-color: #fb4747;
  --white-color: #fff;
  --alt-color: #d0e5ff;
  --color-gradient: linear-gradient(to right, var(--main-color), #d0e5ff);
  --blue-color: #00c4c4;
  --orange-color: #ff864a;
  overflow-x: hidden;
  max-width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.tutorial-link .coloured-return-button i{
  display: none;
}

.tutorial-link .coloured-return-button {
  font-size: 1.8rem;

}

.coloured-return-button.coloured-return-button--hide-icon {
  font-size: 1.8rem;

}

.coloured-return-button--hide-icon i{
  display: none;

}

.tutorial-link {
  position: absolute;
  top: 55px;
  right: 10px;
  font-size: 1.4rem;
  
}

.form-box__form-title {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative;
  padding: 25px 25px 65px;
  border: 1px solid rgb(184, 184, 184);
  border-radius: 20px;
  height: fit-content;
}

.form-box__slogan {
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
  line-height: 120%;
  max-width: 370px;
}

.form-box__input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-box__input-label {
  text-align: right;
  font-size: 1.6rem;
  width: 70px;
  margin-right: 10px;
  font-weight: 600;
  line-height: 120%;
}

.form-box__input {
  font-size: 1.6rem;
  padding: 3px;
  border: none;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 3px;
  width: 250px;
  background-color: transparent;
}

.form-box__input-desc {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.form-box__input:focus {
  outline: none;
}

.form-box__alert {
  color: #f00;
  font-size: 1.6rem;
  font-weight: 600;
}

.form-box__password-wrapper {
  position: relative;
}

.form-box__password-icon-button {
  border: none;
  background: transparent;
  font-size: 2rem;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.simple-loader {
  color: #fff;
  font-size: 5px;
  width: 1em;
  height: 1em;
  margin: 10px auto;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 780px) {

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
 
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(200, 200, 200, .5);
}

::-webkit-scrollbar-thumb:hover{
  background: rgba(200, 200, 200, .5);
}

::-webkit-scrollbar-thumb:active{
  background: rgba(200, 200, 200, .7);
}

}

@media(max-width: 475px) {

  .tutorial-link .coloured-return-button {
      font-size: 1.5rem;
  
   }
}


@media (max-width: 360px) {
  .form-box {
    width: 97vw;
  }

  .form-box__input {
      width: 80%;
      font-size: 1.6rem;
  }
}