.coloured-return-button {
    font-size: 2.3rem;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 999px;
    color: #104f9e;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    }

.coloured-return-button:hover {
    cursor: pointer;
    color: #104f9e; 
}



.coloured-return-button.round {
    border-radius: 999px;
}

.coloured-return-button--top-left {
    position: absolute;
    top: 55px;
    left: 5px;
    z-index: 999;
    font-size: 1.8rem;
    margin-left: 3px;
}

.coloured-return-button--bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 999;
}

@media (max-width: 480px) {
    .coloured-return-button {
        font-size: 1.4rem;
    }

}