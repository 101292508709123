.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(181,214,255,0.8);
    position: fixed;
    display: none;
    z-index: 100000000;
}

.modal--active {
    display: block;
}

.modal__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 40px 30px;
    border-radius: 20px;
    text-align: center;
    
}

.modal__closing-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;

}

@media (max-width: 480px) {
    .modal__box {
        width: 100%;
    }
}


