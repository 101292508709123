
.link-game-page__main-heading {
    font-weight: 600;
    text-align: center;
}

.link-game-page__expressions-wrapper {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    grid-gap: 150px;
    justify-content: center;
    align-items: center;

}



.link-game-page__expression-list {
    list-style-type: none;
    position: relative;
    padding: 0;
}

.link-game-page__expression {
    margin-bottom: 20px;
    display: flex;
    align-items: center;    
    justify-content: center;
    text-align: center;
    padding: 20px 40px;
    font-size: 2.2rem;
    color: #fff;
    background: #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    word-break:break-all;
}


.link-game-page__expression:hover {
    cursor: pointer;
}

.link-game-page__expression--to {
    background-color: #2272A8;
    border: 2px solid #2272A8;

}

.link-game-page__expression--from {
    background-color: #00C4C4;
    border: 2px solid  #00C4C4;
}


.link-game-page__expression--to.link-game-page__expression--active {
    border: 2px solid #004a7b;

}

.link-game-page__expression--from.link-game-page__expression--active {
    border: 2px solid  #007474;


}


.link-game-page__correct-check-img {
    height: 20px;
    width: auto;
    position: absolute;
    right: -40px;

}

.link-game-page__line::before {
    
    /* content: '';
    display: block;
    z-index: 1;
    position: relative;
    width: 6px;
    height: 6px;
    background: #EFC40F;
    border-radius: 50%;
    left: -3px;
    bottom: 4px; */
}

.link-game-page__line::after {
    /* content: '';
    display: block;
    z-index: 1;
    position: relative;
    width: 6px;
    height: 6px;
    background: #EFC40F;
    border-radius: 50%;
    left: 100%;
    bottom: 10px; */

}


@media (max-width: 725px) {
    .link-game-page__expression {
        padding: 10px 20px;
        font-size: 2rem;
    }

    .link-game-page__expressions-wrapper {
        grid-gap: 80px;
    }
}


@media (max-width: 450px) {
    .link-game-page__expression {
        padding: 5px 10px;
        font-size: 1.8rem;
        width: 150px;

    }

    .link-game-page__expressions-wrapper {
        grid-gap: 30px;
    }
}

@media(max-width: 400px) {
    .link-game-page__main-heading { 
        margin-top: 40px;
    }

    .link-game-page__expression {
        width: 130px;
        font-size: 1.6rem;

    }
}


@media(max-width: 360px) {

    .link-game-page__expression {
        width: 120px;

    }
}