.transparent-button {
    font-size: 1.6rem;
    padding: 5px 15px;
    background-color: transparent;
    border: none;
    color: #2272A8;
    cursor: pointer;

}

.transparent-button--top-left {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 999;
    font-size: 1.8rem;
}

.transparent-button--bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 999;
}

.transparent-button--bottom-right {
    font-size: 1.6rem;
    margin-right: 5px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}

.transparent-button:hover {
    color: var(--secondary-color);
}

@media (max-width: 480px) {
    .transparent-button {
        font-size: 1.4rem;
        padding: 5px 12px;
    }


    .transparent-button--support-button {
        font-size: 1.4rem;
        width: 33px;
        height: 33px;
    }
}