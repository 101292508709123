.register-alert-box {
    margin-top: 50px;
    background-color: #fff;
    padding: 80px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 95%;
}

.register-alert-box__title {
    font-size: 2.4rem;
    text-transform: uppercase;
    color: var(--orange-color);
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-bottom: 10px;
}

.register-alert-box__text-content {
    color:  #2272a8;
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 1.2px;
    line-height: 120%;
    margin-bottom: 2px;
    text-align: center;
}

.register-alert-box__icon {
    width: 100px;
    height: auto;
    padding: 20px 0;
}

@media (max-width: 1035px) {
    .register-alert-box {
        padding: 30px;
    }
}

@media (max-width: 350px) {
    .register-alert-box {
        padding: 10px;
    }
}