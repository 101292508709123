.test-page__wrapper {
    font-size: 1.6rem;
    padding: 10px;
    max-width: 100%;
}

.test-page__main-heading {
    font-weight: 700;
    text-align: center;
    font-size: 2.5rem;
}

.test-page__heading {
    font-weight: 700;
    text-align: center;
    font-size: 2.2rem;
}

.test-page__paragraph {
    margin: 10px 0;
}

.test-page__paragraph--center {
    text-align: center;
}

.test-page__inputs-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
}

.test-page__input-label {
    margin-right: 10px;
}
.test-page__input {
    border-radius: 5px;
    border: 1px solid #000;
    height: 30px;
    padding: 0 5px;
}
.test-page__wrapper .simple-loader {
    color: #104f9e;
    font-size: 1.3rem;
    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    transform: translate(-50%, - 50%);
}
.test-page__wrapper a {
    text-decoration: none;
}
.test-page__start-button {
    background-color: #104f9e;
    color: #fff;
    font-size: 2rem;
    border: none;
    outline: none;
    display: block;
    padding: 5px 15px;
    border-radius: 20px;
    margin: 10px auto;
    text-decoration: none;
}
.test-page__start-button:disabled {
    background: #ccc;
}

.test-page__activity-list {
    list-style-type: upper-roman;
    font-weight: 700;
}

.test-page__activity-list ol{
    font-weight: 400;
}
 .test-page__activity-list-title {
    font-weight: 700;
}
.test-page__insert-answers-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.test-page__insert-answer {
    margin: 10px;
    padding: 10px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.test-page__insert-answer:hover {
    cursor: pointer;
}

.test-page__insert-answer:hover, .test-page__insert-answer--selected {
    transform: scale(1.1);

}


.test-page__expression-answers-wrapper {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
}

.test-page__expression-answer-wrapper {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    justify-items:flex-start ;
}


.test-page__sentence-answers-wrapper {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
}

.test-page__sentence-answer-wrapper {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    justify-items:flex-start ;
}

.test-page__percent {
    font-size: 6rem;
    text-align: center;
    font-weight: 600;
}

@media (max-width: 600px) {

.test-page__inputs-wrapper {
    grid-template-columns: repeat(1, 1fr);
}

.test-page__input-wrapper {
    display: grid;
    grid-template-columns: 90px 170px;
}


} 