.coloured-button {
    font-size: 2.3rem;
    padding: 12px 24px;
    background-color: #b5d6ff;
    border: 1px solid #b5d6ff;
    border-radius: 999px;
    color: #fff;
}

.coloured-button:hover {
    background-color: transparent;
    color: var(--main-color);
    cursor: pointer;
}

.coloured-button--top-right {
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 1000;
    font-size: 1.8rem;
    margin-right: 3px;
}

.coloured-button--bottom-right {
    font-size: 1.6rem;
    margin-right: 5px;
    position: absolute;
    bottom: 12px;
    right: 10px;
    z-index: 1000;
}

.coloured-button--support-button {
    width: 39px;
    height: 39px;
    color: #104f9e;
    padding: 5px;
}

.blue-button, .orange-button {
    font-size: 1.8rem;
    border-radius: 3000px;
    font-weight: 700;
    
}

.blue-button.up, .orange-button.up {
    text-transform: uppercase;
}

.coloured-button.round {
    border-radius: 999px;
}

.blue-button {
    background-color: var(--blue-color);
    border-color: var(--blue-color);
}

.orange-button {
    background-color: var(--orange-color);
    border-color: var(--orange-color);
    border-width: 2px;
}

.orange-button--outlined {
    background-color: transparent;
    color:  var(--orange-color);
    border-color: var(--orange-color);
}



.blue-button:hover  {
    color: var(--blue-color);
}

.orange-button:hover {
     color: var(--orange-color); 
}
.orange-button--outlined:hover {
    background-color: var(--orange-color);
    color:  #fff;
}


@media (max-width: 480px) {
    .coloured-button--support-button {
        font-size: 1.4rem;
        width: 33px;
        height: 33px;
    }

}