.form-box__slogan.form-box__slogan--register {
    color: var(--orange-color);
}

.form-box__password-wrapper {
    position: relative;
}

.form-box__password-icon-button {
    border: none;
    background: transparent;
    font-size: 1.6rem;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}




