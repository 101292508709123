.progress-bar-wrapper {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
   
   }
    .progress {
       width: 400px;
       height: 25px;
       overflow: visible;
       border-radius: 50px;
       background-color: #edffed;
    }

 .progress-bar:after {
    z-index: 0;

    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 100%;
    right:  -12px;
    background-color: rgb(0, 156, 0);
    

 }

 .progress-bar {
    position: relative;
    overflow: visible;
    background-color: rgb(60, 214, 60);
    border-radius: 50px;
    z-index: 1;
 }
   
    .progress-label {
      margin-right: 5px;
       color: rgb(0, 156, 0);
       font-weight: 600;
       font-size: 1.8rem;
    }
   
    .progress-label--translated-left {
        transform: translateX(-50%);
    }


    @media(max-width: 530px) {

        .progress {
            width: 250px;
        }
    }

    @media(max-width: 370px) {

      .progress {
          width: 200px;
      }
  }