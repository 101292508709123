.lesson-selection-page-content {
}

.lessons-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style-type: none;
    grid-gap: 40px;
    padding-left: 0;

}


.lesson-wrapper {
    display: block;
    border-radius: 30px;
    position: relative;
    height: 135px;
    width: 300px;
}

.lesson-title, .lesson-progress-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 2rem;
    font-weight: 600;
    width: 190px;
    
}

.lesson-progress-wrapper {
    background-color: #fff;
    position: relative;
    height:45px;
    overflow: hidden;
    border-bottom-right-radius: 20px;

}

.lesson-title {
    height: 90px;
    text-align: center;
    padding: 10px;
    border-top-right-radius: 20px;


}


.lesson-img-wrapper {
    position: absolute;
    height: 150px;
    width: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 9;
}

.lesson-text-wrapper {
    position: relative;
    top: 50%;
    transform: translate(35% ,-50%);
}


.lesson-number-wrapper  {
    position: absolute;
    top: 0;
    left: 0;

}



.lesson-number-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 3px), -50%);
}


.lesson-number-img {
    width: 40px;
    height: auto;
}

.lesson-link {
    color: #000;
    text-decoration: none;

}
.lesson-img {
    height: 100%;
    width: auto;
    position: relative;

}

.lesson-number-number {
    color:#305aa6;
    font-size: 2rem;
    font-weight: 600;
}

.lesson-title__number {
    font-size: 2.5rem;
}

.lesson-title {
   color:  #305aa6;
    background: #fff;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 55px;
}


.lesson-progress {
    background-color: #5b85d1;
    color: #fff;
    position: absolute;
    width: 80px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 0 0 20px;
    padding-left: 10px;
    font-size: 2.2rem;
}

.lesson-progress--finished {
    background-color: #39cb39;
}

.lesson-progress--started {
    background-color: #fdd044;
}

@media(max-width:1366px) {
    .lessons-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 850px)  {
    .lessons-list {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media (max-width: 400px)  {
   
}