.add-key-modal {
    display: flex;
flex-direction: column;
align-items: center;
max-width: 330px;
}

.add-key-modal__text {
    font-size: 1.6rem;
    text-align: left;
    margin: 0;
    width: 100%;
}

.add-key-modal__header {
    font-size: 1.6rem;
    margin: 2px 0;
    font-weight: 600;
    text-align: left;
    width: 100%;
}
.add-key-modal__button {
    background-color: #fff;
    padding: 10px;
    width: 120px;
    display: block;
    margin: 5px auto;
    outline: none;
    border: none;
    box-shadow: 2px 4px 5px rgb(206, 206, 206);
    border-radius: 500px;
    background: rgb(60, 214, 60);
    font-size: 1.9rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
} 

.form-box__input-wrapper--add-key-modal {
    margin: 20px 0;
}
.form-box__input-label--add-key-modal {
    width:fit-content;
}
