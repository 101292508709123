.translate {
    position: absolute;
    right: 10px;
    top: 50px;
    display: none;
    z-index: 99999999999999;
}

.translate--active {
    display: block;
}

.translate__box {
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 20px;
    text-align: center;
    max-width: 300px;
    
}

.translate__closing-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;

}

.translate-box-content {
    display: flex;
flex-direction: column;
align-items: center;
max-width: 330px;
}

.translate-box-content__text {
    font-size: 1.6rem;
    text-align: left;
    margin: 0;
    width: 100%;
}

.translate-box-content__header {
    font-size: 1.6rem;
    margin: 2px 0;
    font-weight: 600;
    text-align: left;
    width: 100%;
}

.translate-box-content__gray-header {
    font-size: 1.6rem;
    margin: 2px 0;
    font-weight: 600;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    color: #aaa;

}


.translate-box-content__button {
    background-color: #fff;
    padding: 10px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
    outline: none;
    border: none;
    box-shadow: 2px 4px 5px rgb(206, 206, 206);
    border-radius: 500px;
    background: rgb(60, 214, 60);
    font-size: 1.7rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
} 

.translate-box-content__button:disabled{
    pointer-events: none;
    background: #aaa;
}

.translate-box-content__icon-expand {
    transition: transform .5s ease;
}

.translate-box-content__icon-expand--transfom-up {
    transform: rotate(180deg);
}

@media (max-width: 480px) {
    .translate__box {
        width: 100%;
    }
}


