.progress-modal {
    background-color: rgba(181,214,255,0.8);
    position: fixed;
    display: none;
    z-index: 98;
}

.progress-modal--active {
    display: block;
    top:0;
    left:0;
    bottom:0;
    right: 0;
}

.progress-modal__box {
    position: fixed;
    top: 100px;
    right: 0;
    background-color: #fff;
    padding: 0px 30px;
    border-radius: 20px 0 0 20px;
    text-align: center;
    transform: translateX(100%);
    transition: transform .5s ease;
    z-index: 99;

}

.progress-modal__box--active  {
    transform: translateX(0);

}

.progress-modal__bookmark {
    position: absolute;
    left: 0;
    top: 100px;
    transform:translateX(-64%) rotate(270deg);
    background: #fff;
    font-size: 1.8rem;
    padding: 10px 15px;
    border-radius: 20px 20px 0 0;
    color: rgb(16,79,158);
    font-weight: 600;
    -webkit-box-shadow: -5px -5px 10px -5px  #104f9e;
    -moz-box-shadow: -5px -5px 10px -5px  #104f9e;
    box-shadow: -5px -5px 10px -5px  #104f9e;

}

.progress-modal__bookmark:hover {
    cursor: pointer;
}


.progress-modal__closing-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;
    display: none;

}

.progress-modal__title {
    font-size: 1.5rem;
    margin: 20px 0;
    font-weight: 600;
}

.progress-modal__content-wrapper {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-gap: 50px;
    max-width: 570px;
}

.progress-modal__number-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.progress-modal__number {
    font-size: 6rem;
    font-weight: 600;
    color: rgb(255, 134, 74);
}

.progress-modal__description {
    font-size: 1.4rem;
    
}


.progress-modal__description--bold {
    font-weight: 700;
    font-size: 1.6rem;
}
.progress-modal__line-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.progress-modal__line-description {
    font-size: 1.5rem;
    margin-top: 20px;
}

.progress-modal__footer {
    font-size: 1.4rem;
    color: #888;
    margin: 5px 0;
}

@media (max-width: 740px) {
    .progress-modal__content-wrapper {
        grid-template-columns: 1fr;
        width: 100%;
    grid-gap: 20px;


    }

    .progress-modal__closing-button {
        display: block;
    }

    .progress-modal__title {
        margin-top: 30px;
    }

    .progress-modal__bookmark {
        display: none;
    }

    .progress-modal__box {
        position: absolute;
    }
}


