.navbar-container {
    width: 100%;
    background: rgb(181,214,255);
    background: linear-gradient(90deg, rgba(181,214,255,1) 0%, rgba(16,79,158,1) 60%);
    position: relative;
    z-index: 99999;
    -webkit-box-shadow: -2px 5px 5px -5px  #104f9e;
    -moz-box-shadow: -2px 5px 5px -5px  #104f9e;
    box-shadow: -2px 5px 5px -5px  #104f9e;

}

.navbar-container--alt-bg {
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

nav {
    margin: 0 auto;
    width: 100%;
    /* max-width: 1600px; */
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.navbar-logo-link {
    position: relative;
}

.navbar-tutorial-link {
    font-size: 1.6rem;
    text-decoration: none;
    color: #d0e5ff;
    font-weight: 600;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.navbar-tutorial-link:hover {
    color: #fff;
}

.navbar-tutorial-link--text {
    text-align: center;
}
.logo-oval-div {
    width: 180px;
    height: 170px;
    background-color: #fff;
    border-radius: 200px 200px;
    position: absolute;
    top: 0;
    /* left: -50%; */
    transform: translate(-12%, -60%);
    z-index: -1000;
}

.navbar-logo {
    height: 50px;
}



.user-account-button {
    font-size: 2.8rem;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: #fff;
}

.menu-list {
    font-size: 1.6rem;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 10px;
    list-style: none;
    background: #fff;
    border-radius: 20px;
    overflow: visible;
    display: none;
    padding-left: 0;
    z-index: 999;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
}

.menu-list-button:last-child {
    border-radius: 0 0 15px 15px;
}

.menu-list_header {
    background-color: #104f9e;
    padding: 5px;
    color: #fff;
    border-radius: 15px 15px 0 0;
}

.menu-list_header-text {
    margin: 5px 0;
}


.navbar-icon {
    width: 30px;
    height: 30px;
    margin-left: 5px;

}

.navbar-icon--resize {
    width: 32px;
    height: 32px;
}

.navbar-icon {
    fill: #d0e5ff;

}


.navbar-icon:hover {
    fill: #fff;

}

.user-account-button.active .menu-list {
    display: block;
}

.user-account-button.invisible {
    display: none;
}

.user-name {
    font-size: 1.6rem;
    margin-right: 10px;
}

.menu-list-button, .menu-list-link {
    background-color: #fff;
    border: none;
    width: 170px;
    text-align: center;
    border: 5px solid #fff;
    padding: 5px;
    cursor: pointer;
}

.menu-list-button:hover, .menu-list-link:hover {
    border: 5px solid var(--alt-color);
    color: #000;

}

.menu-list-link {
    color: #000;
    display: block;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
}

.nav-icon {
    width: 25px;
    height: auto;
    margin-left: 10px;
}

.navbar-icon:hover {
    cursor: pointer;
}

.navbar-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.menu-list-button--help {
    position: relative;
}
.menu-list-button--activity-button {
    display: none;
}

.help-button_notification {
    position: absolute;
    background-color: #fff;
    display: none;
    padding: 10px;
    width: 250px;
    transition: .1s;
    left: -10px;
    top: 0;
    transform: translateX(-100%);
    border-radius: 20px;
}
.help-button_notification-content {
    margin-bottom: 0;
}

.menu-list-button--help:hover .help-button_notification
{
    display: block;
}
@media (max-width: 740px) {
    .menu-list-button--activity-button {
        display: block;
    }
}

@media (max-width: 480px) {
    nav {
        padding: 0 10px;
}
.form-box__password-wrapper--change-password-modal {
    display: flex;
    flex-direction: column;
}


.navbar-logo {
    font-size: 4.5rem;
}

.user-account-button {
    font-size: 2.4rem;
}

.navbar-icon {
    width: 28px;
    height: 28px;
}

.navbar-icon--resize {
    width: 30px;
    height: 30px;
}

.help-button_notification-content {
    font-size: 1.3rem;
}


.user-account-button {
    flex-direction: column;
}
.user-name {
    display: none;
}
}


@media (max-width: 380px) {
 .coloured-button.add-key-button {
     font-size: 1.4rem;
 }

.nav-icon {
    width: 20px;
}

.form-box__input.form-box__input--change-password-modal {
    width: 150px;
}
}