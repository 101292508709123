.language-selection-page-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    max-width: 1100px;
    /* background-color: var(--alt-color); */
    /* border: 1px solid rgb(184, 184, 184);
    border-radius: 20px;
    box-shadow: 5px 7px 10px rgb(168, 168, 168); */
}
.language-pair-link-wrapper {
    padding-bottom: 20px;
}
.language-pair-link {
    display: block;
    text-decoration: none;
    font-size: 2rem;
    color: #000;
    text-align: center;
    /* padding: 15px 20px; */
    margin: 20px 25px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.language-flag {
    width: 120px;
    margin: 10px;
    height: auto;
    border-radius: 50%;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    
}


.language-pair-link--disabled {
    color: gray;
    pointer-events: none;

}


.language-flag--disabled {
    -webkit-filter: grayscale(1) brightness(1.75); 
    filter: grayscale(1) brightness(1.75);
}


.language_notification {
    position: absolute;
    background-color: #fff;
    display: none;
    padding: 10px;
    width: 150px;
    transition: .1s;
    box-shadow: 2px 4px 5px rgb(206, 206, 206);
    border-radius: 500px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    background: rgb(60, 214, 60);
}



.language_notification-content {
    font-size: 1.4rem;
    line-height: 110%;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
    
}
.language-pair-link-wrapper {
    position: relative;
}
.language-pair-link-wrapper--disabled:hover {
    cursor: pointer;
}
.language-pair-link-wrapper--disabled:hover .language_notification{
    display: block;
}




@media (max-width: 600px) {
    .language-pair-link {
        margin: 20px 10px;
    }
}

@media (max-width: 430px) {
    .language-selection-page-content {
        padding: 0;
    }

    .language-pair-link {
        font-size: 1.8rem;
    }
}

@media (max-width: 330px) {
    .language-selection-page-content {
        padding: 0;
    }

    .language-pair-link {
        font-size: 1.6rem;
    }
}