.homepage-box {
    background-color: #fff;
    padding: 25px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 20px;
    height: fit-content;

}

.homepage-box__slogan {
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

.homepage-box__select-wrapper {
    display: flex;
    flex-direction: column;
}

.homepage-box__select-slogan {
    font-size: 1.8rem;
    text-align: center;
    line-height: 120%;
    font-weight: 600;
    max-width: 370px;
    margin-top: 25px;
}

.homepage-box__select-div {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-box__select-slogan--register {
    color: var(--orange-color);
}

.homepage-box__select-slogan--login {
    color: var(--blue-color);
}

