.type-game-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.type-game-page__main-heading {
    font-weight: 600;
    text-align: center;
}



.type-game-page__flashcard-wrapper {
    margin-top: 20px;
    padding: 30px;
    background: #fff;
    border-radius: 20px;
}


.type-game-page__flashcard-img-wrapper {
    max-width: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.type-game-page__expression-wrapper {
    display: flex;
    align-items: center;
}
.type-game-page__flashcard-img {
    height: 300px;
    width: auto;
}

.type-game-page__flag-img {
    width: 45px;
    height: auto;
    margin-right: 10px;
}

.type-game-page__expression-wrapper {
    margin-top: 10px;
}

.type-game-page--expression-input, .type-game-page--expression-text {
    font-size: 2.5rem;
}

.type-game-page--expression-input {
    max-width: 50vw;
}

.type-game-page--proceed-button {
    margin-top: 20px;
    background-color: #2272A8;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
}

.type-game-page__submit-message {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 5px;
    color: #fff;
    background: #ccc;
    text-align: center;
}

.type-game-page__submit-message--3 {
    background-color: #33cc00;

}

.type-game-page__submit-message--2 {
    background-color: #00C4C4;
}


.type-game-page__submit-message--1 {
    background-color: #CC0033;

}


.type-game-page .coloured-return-button {
    margin-top: 20px;
}


@media (max-width: 620px) {
    .flash-card {
        min-width: 95vw;
        width: auto;
        margin: 0 5px;
    }

    .type-game-page__flashcard-img-wrapper {
        max-width: 300px;
    }
}

@media (max-width: 460px) {
   

    .type-game-page__flashcard-img {
        height: 200px;
    }



    .type-game-page__main-heading { 
        margin-top: 40px;
    }

}


@media (max-width:350px) {
    .type-game-page__flashcard-img-wrapper {
        max-width: 250px;
    }
}