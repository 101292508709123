.cert-page__img {
    display: block;
    max-width: 800px;
    width: auto;
    margin: 10px 0;
}

.cert-page {
    font-size: 1.6rem;
    max-width: 1100px;
    font-weight: 700;
}

.cert-page__cert-button {
    border: none;
    border-radius: 5px;
    font-size: 2.4rem;
    color: #fff;
    background-color: #3cd63c;
    font-weight: 600;
    padding: 10px 20px;
}

.cert-page__cert-button--disabled {
    background-color: #aaa;
}

.cert-page__cert-button--disabled:hover {
    cursor: not-allowed;
}

.cert-page__cert-button--mock {
    font-size: 1.5rem;
    padding: 5px 10px;


}
.cert-page__cert-button--mock:hover {
    cursor: default;

}

.cert-page__cert-button-mock-text {
    font-weight: 400;
    margin: 0 10px;
}

.cert-page__cert-button-mock-wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.cert-page__progress-wrapper {
    width: 100%;
align-items: center;
    display: grid;
    grid-template-columns: 150px auto;
}

.cert-page__level-image {
    height: 70px;
    width: auto;
}
.cert-page__level-image-wrapper {
    margin-right: 50px;
    display: flex;
    max-width: 100px;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.cert-page__progress-wrapper {
    margin-top: 10px;
}
.cert-page__level-desc {
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 600;
}
.cert-page__text {
    font-weight: 400;
}

.cert-page__unordered-list {
    font-weight: 400;
}

.cert-page__title {
    font-weight: 600;
    margin: 20px 0;
}




@media (max-width: 860px) {
    .cert-page__img {
        width: 100%;
    }
    .cert-page {
        margin: 0 10px;
    }
}


@media (max-width: 580px) {
    .cert-page__progress-wrapper { 
            display: flex; 
        flex-direction: column;
    }

}