

.contact-support_notification {
    position: absolute;
    background-color: #fff;
    display: none;
    padding: 10px;
    width: 250px;
    transition: .1s;
    border: 1px solid rgb(206, 206, 206);
    box-shadow: 2px 4px 5px rgb(206, 206, 206);
}

.coloured-button--support-button {
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}
.coloured-button--support-button:hover {
    background-color: #fff;
}

.coloured-button--top-right .contact-support_notification, 
.transparent-button--top-right .contact-support_notification {
    right: 110%;
    top: 0%;
}

.coloured-button--bottom-right .contact-support_notification, 
.transparent-button--bottom-right .contact-support_notification {
    right: 110%;
    bottom: 0%;
}

.contact-support_notification-content {
    font-size: 1.4rem;
    line-height: 110%;
    margin-bottom: 0;
    color: #104f9e;;
}

.coloured-button--support-button:hover .contact-support_notification,
.transparent-button--support-button:hover .contact-support_notification{
    display: block;
}

.contact-support__tutorial-link {
    text-decoration: none;
}

@media (max-width: 480px) {
    .contact-support_notification-content {
        font-size: 1.3rem;
    }

}