.grammar-page {
    margin-top: 50px;
}

.grammar-page__page-title {
    text-align: center;
}

.grammar-page__desc {
    text-align: center;
    font-size: 1.6rem;
    max-width: 1366px;
}

.grammar-page__content-section {
    margin: 0 auto;
    width: 100vw;
    max-width: 600px;
    overflow-x: hidden;
}

.grammar-page__content {
    border: 1px solid #104F9E;
    background-color: #fff;
    font-size: 1.6rem;
    margin: 0 5px;
}

.grammar-page__table {
    background-color: #fff;
    font-size: 1.6rem;
    margin: 0 5px;
}

.grammar-page__table--border {
    border: 1px solid;

}

.grammar-page__table td,  .grammar-page__table th{
    padding: 4px 5px;
   vertical-align: text-top;

}

.grammar-page__content-header {
    background-color: #104F9E;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0
}

.grammar-page__content-inner-section-flex {
    display: flex;
    justify-content: space-around;
}

.grammar-page__content-inner-section {
    padding: 8px 0;
}

.grammar-page__content-row {
    padding: 0 5px ;
    font-size: 1.6rem;
    margin-bottom: 0;
}

.grammar-page__span-bold {
    font-weight: 600;
}

.grammar-page .alice-carousel {
    position: relative;
    margin-top: 80px;
    width: 100%;
   
    /* overflow: hidden; */
}

.grammar-page .alice-carousel__dots {
    display: none;
}

.grammar-page .alice-carousel__prev-btn, .grammar-page .alice-carousel__next-btn  {
    position: absolute;
    width: auto;
    top: -30px;
    transform: translateY(-50%);

}

.grammar-page .alice-carousel__prev-btn {
    right: 52%;
    text-align: left;
}

.grammar-page .alice-carousel__next-btn {
   left: 52%;
    text-align: right;
}

.grammar-page .alice-carousel__next-btn-item, .grammar-page .alice-carousel__prev-btn-item {
    width: 25px;
    height: 25px;
    border: 1px solid #104F9E;
    border-radius: 100%;
    position: relative;
    padding: 0;
    background-color: #fff;
}

.grammar-page .alice-carousel__prev-btn [data-area]::after, .grammar-page .alice-carousel__next-btn [data-area]::after {
    position: absolute;
    top: 49%;
    transform: translate(-50%,-50%);
    font-size: 1.6rem;
}

.grammar-page .alice-carousel__prev-btn [data-area]::after {
    left: 49%;
}

.grammar-page .alice-carousel__next-btn [data-area]::after {
    left: 55%;
}