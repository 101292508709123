.homepage-content {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    grid-gap: 80px;
    /* align-items: center; */
    padding: 0 50px;
    justify-content: center;
    /* position: relative; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.slogan-container {
    margin-top: 100px;

}

.slogan-text {
    font-size: 6rem;
    color: #fff;
    text-shadow: 3px 3px 4px black;
    /* font-family: 'Press Start 2P', cursive; */
}

.second-slogan-container {
    width: 600px;
}

.homepage-bg-slide {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
}

.slogan-text--bold {
    font-weight: 600;
    /* font-family: 'Press Start 2P', cursive; */
}

.homepage-bg-slide--1 {
    background-image: url('./zdj_1.webp');
}

.homepage-bg-slide--2 {
    background-image: url('./zdj_2.webp');
}


.homepage-bg-slide--3 {
    background-image: url('./zdj_3.webp');
}


.homepage-bg-slide--4 {
    background-image: url('./zdj_4.webp');
}


.homepage-bg-slide--5 {
    background-image: url('./zdj_5.webp');
}

.homepage-bg-slide--6 {
    background-image: url('./zdj_6.webp');
}

.homepage-bg-slide--7 {
    background-image: url('./zdj_7.webp');
}

.homepage-bg-slide--8 {
    background-image: url('./zdj_8.webp');
}

.homepage-bg-slide--9 {
    background-image: url('./zdj_9.webp');
}

.homepage-bg-slide-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

}



@media (max-width: 1150px) {
    .slogan-text {
        font-size: 5rem;
    }

    .homepage-content {
        grid-gap: 5px;
        grid-template-columns: 1.5fr 1fr;
    }
}

@media (max-width: 900px) {


    .homepage-content {
        display: flex;
        padding: 0;
    }

    .slogan-container {
        display: none;
    }
}



@media (max-width: 360px) {
    .login-input {
        width: 90vw;
        font-size: 1.6rem;
    }
}