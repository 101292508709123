.games-page__main-heading {
    font-weight: 600;
    text-align: center;
}

.games-page__game-list {
    list-style-type: none;
    padding: 0;
    margin-top: 40px;
}

.games-page__game-link {
    border-radius: 10px;
    background-color: #1b5889;
    text-decoration: none;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;

}

.games-page__game-link-icon {
    width: 40px;
    height: auto;
    margin-left: 10px;
}


.games-page__game-link:hover {
    color: #fff;
}

@media(max-width: 400px) {
    .games-page__main-heading { 
        margin-top: 40px;
    }
}