.activity-selection-page-content {
    /* border: 1px solid rgb(184, 184, 184);
    border-radius: 20px;
    box-shadow: 5px 7px 10px rgb(168, 168, 168); */
    /* background-color: var(--alt-color); */
}


.activity-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.activity-link {
    text-decoration: none;
    color: #000;
    width: 200px;
}

.activity {
    margin: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.activity-number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6.4rem;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    
}

.activity-img {
    width: 120px;
    height: 120px;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    border-radius: 50%;
}

.activity-img-wrapper {
    /* background: rgb(245, 208, 0);
    padding: 20px;
    border-radius: 50%; */
}




.activity-desc {
    margin-top: 10px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}




@media (max-width: 600px) {
    .activity {
        margin: 10px;
    }
    
    .activity-link {
        width: 160px;
    }
}

@media (max-width: 430px) {
    
    .activity-desc {
        font-size: 1.8rem;
    }

}

@media (max-width: 330px) {
    
    .activity-desc {
        font-size: 1.6rem;
    }
}
