
 .progress-bar-desc {
    color: rgb(60, 214, 60);
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    margin-bottom: .5rem;
}
                                                         
.flash-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: var(--alt-color);
    background-image: url('./glob.png');
    background-position: left bottom;
    background-size: 200px auto;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100vw;
}

.flash-card-grid-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 20px 40px;
    margin: 0 auto;
    margin-top: 40px;
    justify-items: self-end;
    width: 100vw;
    max-width: 1366px;
}
.flash-card-grid-wrapper--with-progressbar {
    margin-top: 20px;
}

.flash-card-flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.points-wrapper {
    display: flex;
    flex-direction: column;
    justify-self: left;
    margin-left: 20px;
    justify-content: center;
    background-color: #b5d6ff;
    /* border: 1px solid rgb(184, 184, 184); */
    border-radius: 20px;
}

.points-wrapper--with-progressbar {
    margin-top: 40px;
}

.point {
    margin: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.point:hover .point-desc {
    color: #0a58ca;
}
.point-number {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    width: 72px;
    height: 72px;
    border: none;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #b5d6ff;
    outline: none;
    border-radius: 100%;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
}


.point-number.know {
    border: 2px solid rgb(60, 214, 60);
    background-color: rgb(60, 214, 60);
}

.point-number.not-sure {
    border: 2px solid rgb(255, 237, 71);
    background-color: rgb(255, 237, 71);
}

.point-number.dont-know {
    border: 2px solid rgb(255, 101, 74);
    background-color: rgb(255, 101, 74);
}

.point-desc {
    font-size: 1.6rem;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.point-number-image {
    width: 60px;
    height: auto;
}

.flash-card-rate__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.flash-card-rate__text {
    color: #000;
    font-size: 1.6rem;
    margin: 0;
}

.flash-card-rate__rate {
    width: 25px;
    height: 25px;
    margin-left: 3px;
    transition: transform .3s ease;
    fill: #104F9E;
}
.flash-card-rate__rate:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.flash-card-rate__rate--up-active {
    fill: #00ff00;
}

.flash-card-rate__rate--down-active {
    fill: #ff0000;
}


@media(max-width: 860px) {

    .progress-bar-wrapper {
        margin-top: 0px;

       }
    .flash-card-grid-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .points-wrapper {
        flex-direction: row;
        margin-top: 20px;
        margin-left: 0;

    }

    .progress-bar-desc {
        margin-top: 30px;
    }
    .flash-card__support-btn {
        bottom: 5px;
        right: 5px;
        margin: 0;
    }
    .flash-card-container {
        padding-bottom: 50px;
    }
}

@media(max-width: 530px) {

    .progress {
        width: 250px;
    }

    .flash-card-grid-wrapper {
        padding: 40px 0 0;
    }

    .point {
        margin: 20px;
    }

    .point-number {
        font-size: 60px;
        width: 62px;
        height: 62px;
    }

    .point-desc {
        font-size: 1.4rem;
    }
}

@media(max-width: 360px) {
    .point {
        margin: 10px;
    }

    .point-number {
        font-size: 55px;
        width: 57px;
        height: 57px;
    }
    .point-number::after {
        width: 30px;
        height: 30px;
    }

    

    .point-desc {
        font-size: 1.3rem;
    }
}
