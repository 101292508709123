.flashcard-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flashcard-table .table-img {
    width: 200px;
    height: auto;
    padding: 10px;
}

.flashcard-table .rc-table-row {
    transition: all .3s ease;
}
.flashcard-table .rc-table-row:hover {
    background: rgb(194, 194, 194);
    cursor: pointer;
}

.flashcard-table .pagination-wrapper {
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin: 10px auto;
    justify-content: center;
}

.flashcard-table .pagination-button {
    margin: 0 20px;
}

.flashcard-table .button-std {
    border: none;
    border-radius: 1000px;
    background-color: rgb(0, 156, 0);
    font-size: 1.5rem;
    padding: 5px 10px;
    color: #fff;
}