.level-selection-page-content {
    /* border: 1px solid rgb(184, 184, 184);
    border-radius: 20px;
    box-shadow: 5px 7px 10px rgb(168, 168, 168); */
    /* background-color: var(--alt-color); */
}


.levels-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.level-link {
    text-decoration: none;
    color: #000;
    width: 200px;
}

.level {
    margin: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.level-number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6.4rem;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
}

.level-img {
    width: 120px;
    height: 120px;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
    border-radius: 50%;
}



.level-number.basic {
    background-color: #EFC40F;
    border: 1px solid #EFC40F;
}

.level-number.intermediate {
    background-color: #AF2C5A;
    border: 1px solid #AF2C5A;
}

.level-number.advanced {
    background-color: #0744A5;
    border: 1px solid #0744A5;

}

.level-number:hover {
    background-color: transparent;
}

.level-number.basic:hover {
    color: #EFC40F;
}

.level-number.intermediate:hover {
    color: #AF2C5A;
}

.level-number.advanced:hover {
    color: #0744A5;
}

.level-desc {
    margin-top: 10px;
    font-size: 2rem;
    text-transform: uppercase;
    min-height: 60px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.level-progress-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    color: rgb(60, 214, 60);
}

.level-progress {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    color: rgb(0, 156, 0)
}


@media (max-width: 600px) {
    .level {
        margin: 10px;
    }
    
    .level-link {
        width: 160px;
    }
}

@media (max-width: 430px) {
    
    .level-desc {
        font-size: 1.8rem;
    }

}

@media (max-width: 330px) {
    
    .level-desc {
        font-size: 1.6rem;
    }
}
