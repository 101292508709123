
.test-game-page__main-heading {
    font-weight: 600;
    text-align: center;
}





.test-game-page__expression-list {
    margin-top: 50px;
    list-style-type: none;
    position: relative;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
}

.test-game-page__expression {
    display: flex;
    align-items: center;    
    justify-content: center;
    text-align: center;
    padding: 20px 30px;
    font-size: 2.2rem;
    color: #fff;
    background: #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    max-width: 200px;
    -webkit-box-shadow: 5px 5px 10px -5px  #104f9e;
    -moz-box-shadow: 5px 5px 10px -5px  #104f9e;
    box-shadow: 5px 5px 10px -5px  #104f9e;
}



.test-game-page__expression--to {
    background-color: #00C4C4;
    border: 2px solid  #00C4C4;

}

.test-game-page__expression--to:hover {
    cursor: pointer;
}

.test-game-page__expression--from {
    margin: 40px auto 0;


    background-color: #2272A8;
    border: 2px solid #2272A8;
}


.test-game-page__expression--correct {
    background-color: #33CC00;

    border: 2px solid #33CC00;
}
.test-game-page__expression--not-correct {
    background-color: #CC0033 ;

    border: 2px solid #CC0033 ;
}




.test-game-page__correct-check-img {
    height: 20px;
    width: auto;
    position: absolute;
    right: -40px;

}



@media (max-width: 870px) {
    
    .test-game-page__expression-list {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width: 450px) {
    .test-game-page__expression {
        font-size: 1.8rem;
        width: 160px;

    }

    .test-game-page__expressions-wrapper {
        grid-gap: 30px;
    }
}

@media(max-width: 400px) {
    .test-game-page__main-heading { 
        margin-top: 40px;
    }

    .test-game-page__expression {
        width: 140px;
        font-size: 1.6rem;

    }
}


@media(max-width: 360px) {

    .test-game-page__expression {
        width: 100px;

    }
}