.milestone-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(220,237,242,0.8);
    position: fixed;
    display: none;
    z-index: 10000;
}

.milestone-modal--active {
    display: block;
}

.milestone-modal__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 20px;
    text-align: center;
}

.milestone-modal__closing-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;

}

.milestone-modal__header {
    color: var(--orange-color);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    font-size: 2.4rem ;
}

.milestone-modal__text {
    color:  #2272a8;
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 1.2px;
}

.milestone-modal__flash-number-wrapper {
    position: relative;
}

.milestone-modal__flash-number-img {
    max-width: 120px;
    margin: 10px 0;
    height: auto;
}

.milestone-modal__flash-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Rampart One', cursive;
    font-size: 5.2rem;
    color: #2272a8;
}

.milestone-modal__button {
    margin: 20px 10px 0 0;
    min-width: 240px;
}

