.notification {
    width: 100vw;
    height: 100vh;
    background-color: rgba(181,214,255,0.8);
    position: fixed;
    display: none;
    z-index: 100000000;
}

.notification--active {
    display: block;
}

.notification__box {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 40px 30px;
    border-radius: 10px;
    text-align: center;
    max-height: 85%;
    overflow-y: scroll;
    
}

.notification__closing-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;
}

.notification__header {
    font-size: 2.2rem;
    font-weight: 600;
    text-align: center;
}

.notification__text {
    font-size: 1.6rem;
}

.notification__text-header {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.notification__text--center {
    text-align: center;
}

.notification__text--short {
    max-width: 500px;
    margin: 10px auto;
}

.notification__img {
    width: 300px;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
}

.notification__img--smaller {
    width: 200px;
}

.notification__img--bigger {
    width: 500px;
}

.notification__ordered-list {
   text-align: left;
   list-style-type: none;
}
.notification__ordered-list-element-number {
    color: rgb(255, 134, 74);
    font-weight: 600;
    font-size: 5rem;
    display: flex;
    align-items: flex-start;
    line-height: 5rem;
}

.notification__ordered-list-element {
   color: #000;
    display: grid;
    grid-template-columns: 40px 1fr;    
}

@media (max-width: 780px) {
    .notification__box {
        width: 100%;
    }
}


