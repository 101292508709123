.tutorial-page__img {
  display: block;
  width: 500px;
  max-width: 100%;
  margin: 10px 0;
}

.language-flag--tutorial {
  width: 80px;
}

.language-pair--tutorial {
  font-size: 1rem;
}

.language-pair-link--tutorial  {
  margin: 2px;
}

.choose-language-header--tutorial {
  font-weight: 600;
  text-align: center;
}

.choose-language-wrapper-tutorial {
  background-image: url('./map.png'), linear-gradient(to right, rgb(208, 229, 255), rgb(208, 229, 255));
  background-position: center;
  padding-top: 20px;
  max-width: 620px;

}

.tutorial-page {
  font-size: 1.6rem;
  max-width: 1100px;
  font-weight: 700;
}

.tutorial-page__text {
  font-weight: 400;
}

.tutorial-page__unordered-list {
  font-weight: 400;
  list-style-type: none;
}

.tutorial-page__unordered-list > li {
  text-indent: -5px;
}
.tutorial-page__unordered-list > li:before {
  content: "-";
  text-indent: -5px;
}

.tutorial-page__title {
  font-weight: 600;
  margin: 20px 0;
}

.tutorial-page__ordered-list-nested-flex-wrapper {
  display: flex;
  align-items: center;
}

.tutorial-page__ordered-list-nested {
  list-style-type: none;
  padding: 0;

}

.tutorial-page__activity-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.tutorial-page__start-link {
  text-decoration: none;
}

.tutorial-page__flex-wrapper {
  display: flex;
  align-items: center;
}

.tutorial-page__flex-wrapper p {
  margin-left: 10px;
}
.tutorial-page__start-button {
  margin: 10px auto;
  display: block;
  font-size: 2rem;
  text-decoration: none;
}


@media (max-width: 860px) {
  .tutorial-page__img {
    max-width: 100%;
    display: block;
    margin: 5px auto;
  }

  .choose-language-wrapper-tutorial {
    max-width: 100%;
  
  }
  .tutorial-page {
    margin: 0 10px;
  }

  .tutorial-page__flex-wrapper {
    flex-direction: column;
  }
}
