.flash-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 10px 20px;
    /* border: 1px solid rgb(184, 184, 184); */
    border-radius: 20px;
    background-color: #fff;
}

.flash-picture-wrapper {
    width: 500px;
    height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flash-content {
    max-width: 500px;
}

.flash-picture {
    width: 550px;
    height: auto;
}

.flash-content {
    margin: 15px 0 0;
    font-size: 1.8rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.flash-phrase {
    margin: 0;
}

.flash-word {
    font-size: 5rem;
}

.icon {
    margin: 10px;
    cursor: pointer;
}

.icon:hover {
    color: var(--main-color);
}

.turnover-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: .1s;
    align-self: flex-end;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-transform: uppercase;
flex-direction: column-reverse;
}

.turnover-button:hover .flash-icon {
    transform: scale(120%);
    fill: #0a58ca;

}

.turnover-button:hover {
    color: #0a58ca;
}

.turnover-icon {
    font-size: 3.5rem;
}

.flash-icon {
    width: 30px;
    height: 30px;
    fill: #000;
}

@media (max-width: 620px) {
    .flash-card {
        min-width: 95vw;
        width: auto;
        margin: 0 5px;
    }

    .flash-picture-wrapper {
        width: 85vw;
        height: calc(85vw / 1.4);
    }

    .flash-picture {
        width: 95vw;
    }

    .flash-content {
        max-width: 85vw;
    }
}

@media (max-width: 460px) {

    .flash-word {
        font-size: 4.2rem;
    }

    .flash-phrase {
        font-size: 1.6rem;
    }

    .icon {
        font-size: 1.6rem;
    }

    .turnover-icon {
        font-size: 3rem;
    }
}
