.page-container {
    min-height: 100vh;
    /* background-image: url('./bg.jpg'); */
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
    
}

.page-container--alt-bg {
    background-image: none;
    background-color: var(--alt-color);
}



.page-container--alt-bg-map {
    background-image: url('./map.png'), linear-gradient(to right, rgb(208, 229, 255), rgb(208, 229, 255));;
    background-repeat: no-repeat,
      no-repeat;
    
      background-position: center,
      center;
}

.page-container--white-bg {
    background: #fff;
}

.page-title {
    font-size: 3.6rem;
    padding-top: 40px;
    text-align: center;
    font-weight: 700;
}

.page-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
    padding: 30px 0;
    align-items: center;
}


@media (max-width: 460px) {
    .page-content {
        /* margin: 50px auto 0; */
        padding: 20px 0;
    }
}

@media (max-width: 360px) {
    .page-title {
        font-size: 3rem;
    }
}

@media (max-width: 300px) {
    .page-title {
        font-size: 2.8rem;
    }
}
